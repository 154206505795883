.container {
    width: 100%;
    height: "300px";
    
  }
  
  .container .carousel .slide img {
    max-height: 100vh;
    max-width: 100vw;
    width: auto; /*100vw*/
  }
  .nocontent {
    position: absolute;
    padding-top: 10%;
    padding-left: 25%;
  }
  @media screen and (max-width: 960px) {
    .nocontent {
      position: absolute;
      padding-top: 0%;
      padding-left: 25%;
    }
    
  }